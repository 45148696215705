"use client";

import { cn } from "@/lib/utils";
import * as Dialog from "@radix-ui/react-dialog";
import { Menu, X } from "lucide-react";
import { useState } from "react";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";

const menuItems = [
  {
    title: "Home",
    href: "#hero",
  },
  {
    title: "Membership",
    href: "#membership",
  },
  {
    title: "Club Architecture",
    href: "#club-architecture",
  },
  {
    title: "Meet The Creators",
    href: "#meet-the-creators",
  },
  {
    title: "Contact",
    href: "#contact",
  },
] as const;

// don't say "Profile" if the user doesn't have one
export function LandingMenu() {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
  };

  return (
    <div className="relative">
      <div className="relative md:hidden">
        <Dialog.Root open={isOpen} onOpenChange={handleOpenChange}>
          <Dialog.Trigger asChild>
            <button
              className="mx-4 flex flex-row items-center rounded-full px-4 py-4
                text-mavins-beige transition-colors hover:bg-transparent
                md:hidden"
              aria-label="Open menu"
            >
              <h2
                className="mr-4 mt-[0.5rem] font-franklinGothic text-[2rem]
                  font-semibold uppercase leading-6.5"
              >
                Menu
              </h2>
              <Menu className="h-6 w-6" />
            </button>
          </Dialog.Trigger>
          {/* https://github.com/radix-ui/primitives/pull/2923#discussion_r1658953421 */}
          {/* Use container={globalThis.document?.body} if the error reappears */}
          <Dialog.Portal>
            <Dialog.Overlay
              className={cn("fixed inset-0 z-50 bg-mavins-beige md:hidden")}
            >
              <Dialog.Content
                className={cn("fixed inset-0 z-50 flex flex-col")}
              >
                <VisuallyHidden.Root asChild>
                  <Dialog.Title>Menu</Dialog.Title>
                </VisuallyHidden.Root>
                <div
                  className="flex items-center justify-between px-4
                    py-[2.063rem]"
                >
                  <h1
                    className="h-12 font-franklinGothic text-5xl font-semibold
                      uppercase leading-[3.875rem] tracking-4 text-mavins-grey
                      hover:text-gray-500"
                  >
                    Menu
                  </h1>
                  <Dialog.Close
                    className="rounded-full px-4 py-[0.7rem] text-mavins-grey
                      transition-colors"
                  >
                    <X className="h-6 w-6" />
                    <span className="sr-only">Close menu</span>
                  </Dialog.Close>
                </div>
                <nav className="flex flex-col gap-6 px-4 py-8">
                  {menuItems.map((item) => (
                    <a
                      key={item.href}
                      href={item.href}
                      onClick={() => handleOpenChange(false)}
                      className={cn(
                        `font-sctoGrotesk text-[2rem] font-medium leading-8.5
                        tracking-4 text-mavins-grey transition-colors
                        hover:text-gray-500`,
                      )}
                    >
                      {item.title}
                    </a>
                  ))}
                  <a
                    href="/login"
                    className={cn(
                      `font-sctoGrotesk text-[2rem] font-medium leading-8.5
                      tracking-4 text-mavins-grey transition-colors
                      hover:text-gray-500`,
                    )}
                  >
                    Login
                  </a>
                </nav>
              </Dialog.Content>
            </Dialog.Overlay>
          </Dialog.Portal>
        </Dialog.Root>
      </div>
      {/* Desktop navigation */}
      <nav
        className="mr-8 hidden h-[7.125rem] flex-row items-center
          gap-[2.8125rem] md:flex"
      >
        {menuItems.map((item) => (
          <a
            key={item.href}
            href={item.href}
            className="p-2 font-franklinGothic text-lg font-semibold uppercase
              leading-3.5 text-mavins-beige transition-colors hover:text-white"
          >
            {item.title}
          </a>
        ))}
        <a
          href="/login"
          className={cn(
            `p-2 font-franklinGothic text-lg font-semibold uppercase leading-3.5
            text-mavins-beige transition-colors hover:text-white`,
          )}
        >
          Login
        </a>
      </nav>
    </div>
  );
}
